import {
  ADD_CURRENT_SALOON,
  INIT_CURRENT_SALOON,
  REMOVE_CURRENT_SALOON,
  UPDATE_CURRENT_SALOON,
  ADD_NEW_CURRENT_SALOON,
  UPDATE_CHECK_FIELDS_CURRENT_SALOON,
} from './types';

export const initCurrentSaloon = (saloons = []) => ({
  type: INIT_CURRENT_SALOON,
  payload: saloons,
});

export const addCurrentSaloon = (saloon) => ({
  type: ADD_CURRENT_SALOON,
  payload: saloon,
});

export const addNewCurrentSaloon = () => ({
  type: ADD_NEW_CURRENT_SALOON,
});

export const removeCurrentSaloon = (id) => ({
  type: REMOVE_CURRENT_SALOON,
  payload: id,
});

export const updateCurrentSaloon = (id, key, value) => ({
  type: UPDATE_CURRENT_SALOON,
  payload: { id, key, value },
});

export const updateCurrentSaloonCheckFields = (saloonId, fieldId, isChecked, fieldName) => ({
  type: UPDATE_CHECK_FIELDS_CURRENT_SALOON,
  payload: { saloonId, fieldId, isChecked, fieldName },
});