import React from 'react'

import {Link} from 'react-router-dom'
import { isAuthenticated } from '../auth'
import {read} from '../user/apiUser'

import Fade from 'react-reveal/Fade'

import Directory from '../images/whair-directory.png'
import WhairGradient from '../images/whair-gradient.png'
import Footer from './Footer'
import WhairHeroLeft from '../images/Whair-hero-left.jpg'
import WhairHeroRight from '../images/Whair-hero-right-02.jpg'
import Foil from '../images/whair-foil.jpg'

import { Parallax } from "react-parallax"

import Video from '../images/future.mp4'
import Device from '../images/iphone_x.png'



class  Home extends React.Component {
    constructor(){
        super()
        this.state = {
            user:"",
        }
    }


    init = (userId) => {
        const token = isAuthenticated().token
        read(userId, token)
        .then(data => {
            if(data){
                if(data.error){
                    this.setState({
                        redirectToSignIn: true
                    })
                } else {
                    this.setState({
                        user: data
                    })
                }
            }
        })
    }

    componentDidMount(){
        const userId = this.props.match.params.userId
        this.init(userId)
    }
    componentWillReceiveProps(props){
        const userId = props.match.params.userId
        this.init(userId)
    }
    
    render(){
        return(
            <div className="black w-100">  
                <section className=" flex flex-wrap flex-nowrap-l bg-white">
                    <div className="vh-75 vh-75-l mb2 mh2 ml4-l mr2-l w-100 w-50-l flex items-end bg-black pa4">
                        <div className="measure measure-l white order-1 order-0-l tl">
                            <h1 className="lh-title white f3 f2-l">A directory and network for hairdressers and barbers</h1>
                            <p className="lh-copy measure-narrow pb1">Sign up now to become part of a growing directory, designed to reunite your clients with you.</p>
                            <div className="mv4"><Link to="/signup" className="mb3 link white bg-black dim ph3 pv2 ttu ba b--white f7 f6-l">Join now</Link></div>
                        </div>  
                    </div>
                    <div className="vh-75 mb2 mh2 mr4-l ml2-l w-100 w-50-l flex items-center justify-center" style={{backgroundImage: `url(${Foil})`, backgroundSize: 'cover', backgroundColor: 'black'}}>
                            <div className="iPhoneOuter">
                                <img src={Device} className="deviceImage"
                                    style={{width: '30vmax'}}
                                />
                                <div className="iphone-video">
                                    <video autostart loop autoPlay muted src={Video} type="video/mp4"
                                        style={{width: '100%'}}
                                     />
                                </div>
                            </div>
                        
                      
                    </div>
                  
                
                </section>  
                {/* <section className="vh-50-l vh-25 ma2 ma3-l tc flex flex-column justify-center items-center" style={{ backgroundImage: `url(${WhairBanner})`, backgroundColor: '#000', backgroundRepeat: 'x-repeat', backgroundSize: 'contain'}}>
                    <h1 className="f2 f-headline-l lh-title ma0 pa0 inter fw9 ttu tracked" style={{color: '#E3CFCD'}}>Coming soon</h1>
                    <h1 className="f2 f-headline-l lh-title ma0 pa0 inter fw9 ttu tracked" style={{color: '#E3CFCD'}}>Coming soon</h1>
                    <h1 className="f2 f-headline-l lh-title ma0 pa0 inter fw9 ttu tracked" style={{color: '#E3CFCD'}}>Coming soon</h1>
                </section> */}
                <Fade>
                    <section className=" mvh-75 flex flex-wrap relative justify-around items-start ma2 ma4-l ph3 pv5 pa5-l" > 
                        <div className="measure-wide-l measure tc tl-l mb4" style={{}}>
                            <h1 className="lh-title f3 mb4 f3-l">Network with other hair professionals and make it easy for clients to find you*</h1>
                            <p className="lh-copy f4-l f5">Start by filling out some basic information, your work history and let us do the rest.</p>
                        </div>
                        <div className="measure-wide-l measure tc tl-l mb4" style={{}}>
                            <ul className="tl f4-l f6 mb5 pl0 lh-copy">
                                <li>Reunite your clients with you*</li>
                                <li>Make recommendations</li>
                                <li>Engage with the industry</li>
                                <li>Showcase your best work</li>
                                <li>Build your network</li>
                                <li>Become a key contributer</li>
                                <li>Free to sign up</li>
                            </ul>
                            <div className="mt3"><Link to="/about" className="mv3 link white bg-black dim ph3 pv2 ttu ba b--dark-gray f6">Find out more</Link></div>
                        </div>
                        <div className="w-50 measure-narrow-l mb4">
                            <img src={Directory} alt="directory" />
                        </div>
                        <p className="pl3 lh-copy" style={{fontSize: '12px', position: 'absolute', bottom: 12, right: 24, }}>*Public directory will be released when we hit 2,500 professionals</p>
                    </section>
                </Fade>
                <Fade>
                    <section className="mvh-50 flex flex-wrap justify-around items-center ma2 ma4-l ph4 pv5 pa5-l" style={{backgroundImage: `url(${WhairGradient})`, backgroundSize: 'cover', backgroundColor: '#ffffff'}}> 
                        <div className="measure order-1 order-0-l tl tc-l mb4" style={{}}>
                            <h3 className="tracked ttu f7 lh-copy">Accounts</h3>
                            <h1 className="lh-title">Client, Salon + Brand Pages</h1>
                            <p className="lh-copy mb3">During this phase of Whair, our primary focus is onboarding hairdressers and barbers. For more information and how to register, see our accounts page.</p>
                            <div className="mt4"><Link to="/accounts" className="mv3 link white bg-black dim ph3 pv2 ttu ba b--dark-gray f6">Find out more</Link></div>
                        </div>
                    </section>
                </Fade>
                <Footer/>
            </div>
        )
                    }
}

export default Home