import React from 'react'
import Footer from './Footer'

const Contact = () => (
    <div>
        <section className="mvh-50 flex flex-wrap justify-center items-center ph4 pv5 pa4-l"> 
            <div className="flex flex-column items-center">
                <h1 className="tc measure lh-title">Contact</h1>
            </div>
        </section>  
        <section className="mvh-50 flex flex-wrap justify-center items-center ph4 pv5 pa5-l"> 
            <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                <p className="measure black">General</p>
                <a href="mailto:hello@whair.co.uk" className="w-50 gray link hover-near-black">hello@whair.co.uk</a>
            </div>
            <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                <p className="measure black">Media relations</p>
                <a href="mailto:media@whair.co.uk" className="w-50 gray link hover-near-black">media@whair.co.uk</a>
            </div>
            <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                <p className="measure black">Partnerships</p>
                <a href="mailto:partnerships@whair.co.uk" className="w-50 gray link hover-near-black">partnerships@whair.co.uk</a>
            </div>
            <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                <p className="measure black">Feedback</p>
                <a href="mailto:feedback@whair.co.uk" className="w-50 gray link hover-near-black">feedback@whair.co.uk</a>
            </div>
            <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                <p className="measure black">Privacy</p>
                <a href="mailto:privacy@whair.co.uk" className="w-50 gray link hover-near-black">privacy@whair.co.uk</a>
            </div>
            <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                <p className="measure black">Instagram</p>
                <a href="https://instagram.com/whair.co.uk" className="w-50 gray link hover-near-black">whair.co.uk</a>
            </div>
            
        </section> 
                <Footer/>



    </div>
)

export default Contact

