import React from 'react'
import Footer from './Footer'
import {Link} from 'react-router-dom'

const Features = () => (
    <div>
        <section className="mvh-50 flex flex-wrap justify-center items-center ph4 pv5 pa4-l"> 
            <div className="flex flex-column items-center">
                <h1 className="tc measure lh-title">Accounts and their features</h1>
            </div>
        </section>  
        <section className="mvh-50 flex flex-wrap justify-center items-center ph4 pv5 pa5-l"> 
            <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                <p className="w-30 black">Hair professionals</p>
                <ul className="w-100 w-50-l black">
                    <li>Reunite your clients with you*</li>
                    <li>Make recommendations</li>
                    <li>Engage with the industry</li>
                    <li>Showcase your best work</li>
                    <li>Build your network</li>
                    <li>Become a key contributor </li>
                    <li>Free to sign up</li>
                    <li>Receive exclusive offers</li>
                </ul>
                <div className="mt4"><Link to="/signup" className="mb3 link white bg-black dim ph3 pv2 ttu ba b--white f7 f6-l">Sign up now</Link></div>
            </div>
            <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                <p className="w-30 black">Clients</p>
                <ul className="w-100 w-50-l black">
                    <li>Find that hair professional you thought you'd lost</li>
                    <li>Create lists of your favourite hair professionals</li>
                    <li>Stay in contact </li>
                    <li>Request recommendations </li>
                    <li>Receive exclusive offers</li>
                </ul>
                <div className="mt4"><Link to="/contact" className="mb3 link white bg-black dim ph3 pv2 ttu ba b--white f7 f6-l">Get in touch</Link></div>
            </div>
            <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                <p className="w-30 black">Salons</p>
                <ul className="w-100 w-50-l black">
                    <li>Appear in searches</li>
                    <li>Increase your reach and widen your audience</li>
                    <li>Create engaging articles</li>
                    <li>Showcase your own products</li>
                    <li>Display brand affiliations</li>
                    <li>Add link to your booking site</li>
                    <li>Post jobs</li>
                    <li>Find temporary staff</li>
                    
                </ul>
                <div className="mt4"><Link to="/contact" className="mb3 link white bg-black dim ph3 pv2 ttu ba b--white f7 f6-l">Get in touch</Link></div>

            </div>
            <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                <p className="w-30 black">Brands</p>
                <ul className="w-100 w-50-l black">
                    <li>Add products to the database</li>
                    <li>Showcase your products</li>
                    <li>Opportunities for product testing</li>
                    <li>Create engaging articles</li>
                    <li>Update your education calendar</li>
                    <li>Update your event calendar </li>
                    <li>Post jobs</li>
                </ul>
                <div className="mt4"><Link to="/contact" className="mb3 link white bg-black dim ph3 pv2 ttu ba b--white f7 f6-l">Get in touch</Link></div>
            </div>
        </section> 
        <Footer/>



    </div>
)

export default Features

