import React, { Component } from "react"
import { confirmEmail } from "../auth"
import { Redirect} from 'react-router-dom'

class ConfirmEmail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            redirect:false,
            message: "",
            error: ""
        }
    }
 
componentDidMount = () => {
        this.setState({ message: "Confirming your email, please hold", error: "" })

        confirmEmail({
            activationToken: this.props.match.params.activationToken
        }).then(data => {
            if (data.error) {
                console.log(data.error)
                this.setState({ error: data.message })
            } else {
                console.log(data.message)
                this.setState({ message: data.message, redirect:true})
            }
        })
    }
 
    render() {
        const {redirect} = this.state;
            if(redirect) return <Redirect to="/community"/>
        return (
            
            <div className="container">
                
                <h2 className="mt-5 mb-5">Email confirmation</h2>
 
                {this.state.message && (
                    <h4 className="bg-success">{this.state.message}</h4>
                )}
                {this.state.error && (
                    <h4 className="bg-warning">{this.state.error}</h4>
                )}
 
                
            </div>
        )
    }
}
 
export default ConfirmEmail