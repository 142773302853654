import React from 'react'
import {Link} from 'react-router-dom'
import Footer from './Footer'
import Directory from '../images/whair-directory.png'

const Soon = () => (
    <div>
        <section className="mvh-50 flex flex-wrap justify-center items-center ph4 pv5 pa4-l"> 
            <div className="flex flex-column items-center">
                <h1 className="tc f3 measure lh-title mv4">Page coming soon</h1>
                <h1 className="tc f-headline-l f-subheadline measure lh-title mb5">Hold please!</h1>
                <img src={Directory} alt="directory" className="w-50 w-20-l"/>
                <Link to="/" className=""><h1 className="tc f3 measure lh-title mv5">Click to return to homepage</h1></Link>
            </div>
        </section>  
    </div>
)

export default Soon

