import React from 'react'
import {Link} from 'react-router-dom'
import Footer from './Footer'

const Faq = () => (
    <div>
        <div className='w-100 pa4  justify-center'>
            <section className="mvh-50 flex flex-wrap justify-center items-center ph2 pv3 pa5-l"> 
                <h1 className="lh-title tl w-100 ph2 pv3 pa5-l">Hair professionals</h1>
                <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                    <p className="measure black">How can I contact my clients?</p>
                    <div className="measure-wide">
                        <p className="lh-copy measure-wide">If, and when, they connect with you on Whair.</p>
                        <p className="lh-copy measure-wide">To remain ethical, hair professionals will not be able to search for clients. </p>
                        <p className="lh-copy measure-wide">Clients you invite will automatically be added to your client list.</p>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                    <p className="measure black">I am from outside of the UK&I, can I still sign up?</p>
                    <div className="measure-wide">
                        <p className="measure-wide">We are currently building Whair for use within the UK&I hair industry.</p>
                        <p className="measure-wide">You are more than welcome to sign up ready, but may not be able to access all features.</p>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                    <p className="measure black">Will my clients be able to book directly through Whair?</p>
                    <div className="measure-wide">
                        <p className="measure-wide">This is a feature we are keen to build and are currently exploring various ways of doing this without disrupting your current salon booking systems. </p>
                        <p className="measure-wide">For now, clients will be able to message you for appointments and can always be referred to your salon/personal booking system.</p>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                    <p className="measure black">How do I share my profile?</p>
                    <div className="measure-wide">
                        <p className="measure-wide">All Whair pages have been designed with mobile in mind, perfect to add to your Instagram bio.</p>
                    </div>
                </div>
            </section>                
            <section className="w-100 mvh-50 flex flex-wrap justify-center items-center ph2 pv5 pa5-l"> 
                <h1 className="lh-title tl w-100 ph2 pv3 pa5-l">Clients</h1>
                <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                    <p className="measure black">When can I sign up?</p>
                    <div className="measure-wide">
                        <p className="measure-wide">We’re currently completing our Minimum Viable Produt (MVP), during this stage we are focussing o signing up hairdressers/barbers ready for you to begin your search.</p>
                        <p className="measure-wide">Once we have reached our target, client accounts will be freely available.</p>
                        <p className="measure-wide">Previous to this, you may be invited by a hair professional as part of trial testing.</p>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                    <p className="measure black">Why should I sign up?</p>
                    <div className="measure-wide">
                        <p className="lh-copy measure-wide">Signing up will allow to access a range of features, including an address book, access to special offers and discounts</p>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                    <p className="measure black">How can I find my hair professional?</p>
                    <div className="measure-wide">
                        <p className="measure-wide">Search by name and location, and if they have signed up you should be able to identify them by their profile photo.</p>
                    </div>
                </div>
            </section>                
            <section className="w-100 mvh-50 flex flex-wrap justify-center items-center ph2 pv5 pa5-l"> 
                <h1 className="lh-title tl w-100 ph2 pv3 pa5-l">Salons</h1>
                <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                    <p className="measure black">When can I sign up?</p>
                    <div className="measure-wide">
                        <p className="measure-wide">Our primary focus is onboarding hairdressers and barbers, during this time default pages might be created for you ready to claim.</p>
                        <p className="measure-wide">Please register your interest to recieve news and updates on salon accounts.</p>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                    <p className="measure black">What’s the difference between a default page and claimed page?</p>
                    <div className="measure-wide">
                        <p className="lh-copy measure-wide">When a hair professional signs up and adds you as part of their work history, a default page for your company will be created using public information from google.</p>
                        <p className="lh-copy measure-wide">This page can then be claimed.</p>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                    <p className="measure black">What if my company has multiple salons?</p>
                    <div className="measure-wide">
                        <p className="measure-wide">There will be an option to add extra salons to your page, including contact details and locations for each of them.</p>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                    <p className="measure black">Can clients make bookings directly from Whair?</p>
                    <div className="measure-wide">
                        <p className="measure-wide">On first release we'll ask you to display a link to your online booking site (if available) in the booking section.</p>
                    </div>
                </div>
            </section>
            <section className="w-100 mvh-50 flex flex-wrap justify-center items-center ph2 pv5 pa5-l"> 
                <h1 className="lh-title tl w-100 ph2 pv3 pa5-l">Brands</h1>
                <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                    <p className="measure black">When can I create a page for my brand?</p>
                    <div className="measure-wide">
                        <p className="measure-wide">During this phase of Whair, we are exclusively signing up hairdressers and barbers. </p>
                        <p className="measure-wide">Please register your interest to recieve news and updates on salon accounts.</p>
                        <p className="measure-wide">If you are on our records, we may contact you in the near future to discuss plans.</p>
                    </div>
                </div>
            </section>               
        </div>
        <Footer/>
    </div>
)

export default Faq

