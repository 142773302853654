import React from 'react';
import { Provider } from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import './App.css';
import 'tachyons';
// import 'antd/es/date-picker/style/index.css';
import 'antd/dist/antd.css';

import store from './redux/store';
import MainRouter from './MainRouter'

const App = () => (
  
    <Provider store={store()}>
      <BrowserRouter>
        <MainRouter/>
      </BrowserRouter>
    </Provider>
  
)

export default App;
