import { createStore, combineReducers, compose } from 'redux';

import userInfoReducer from './user-info/reducer';
import currentSaloonReducer from './current-saloon/reducer';
import previousSaloonReducer from './previous-saloon/reducer';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export default () => createStore(
  combineReducers({
    userInfo: userInfoReducer,
    currentSaloon: currentSaloonReducer,
    previousSaloon: previousSaloonReducer,
  }),
  composeEnhancers(),
);