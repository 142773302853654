import React, { Component } from "react";
import { forgotPassword } from "../auth";
 
class ForgotPassword extends Component {
    state = {
        email: "",
        message: "",
        error: ""
    };
 
    forgotPassword = e => {
        e.preventDefault()
        this.setState({ message: "", error: "" })
        forgotPassword(this.state.email).then(data => {
            if (data.error) {
                console.log(data.error);
                this.setState({ error: data.error })
            } else {
                console.log(data.message);
                this.setState({ message: data.message })
            }
        })
    }
    render() {
        return (
            <div className="container w-100 tc flex flex-column align-center items-center">
                <div>
                    <h1 className="mt5 mb5 fw3">Gosh. Let's get this sorted right away!</h1>
                    {this.state.message && (
                        <h4 className="bg-success">{this.state.message}</h4>
                    )}
                    {this.state.error && (
                        <h4 className="bg-warning">{this.state.error}</h4>
                    )}

                    <form>
                        <div className="form-group mb4">
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Your email address"
                                value={this.state.email}
                                name="email"
                                style={{
                                    transform: `scale(1.5)`
                                    }}
                                onChange={e =>
                                    this.setState({
                                        email: e.target.value,
                                        message: "",
                                        error: ""
                                    })
                                }
                                autoFocus
                            />
                        </div>
                        <button
                            onClick={this.forgotPassword}
                            className="btn btn-raised btn-primary"
                        >
                            Send password reset link
                        </button>
                    </form>
                </div>
            </div>
        )
    }
}
 
export default ForgotPassword