import React from 'react'
import {Link} from 'react-router-dom'
import Footer from './Footer'
import Four04image from '../images/whair-404.png'

const Four04 = () => (
    <div>
        <section className="mvh-50 flex flex-wrap justify-center items-center ph4 pv5 pa4-l"> 
            <div className="flex flex-column items-center">
                <h1 className="tc f3 measure lh-title mv4">Page not found</h1>
                <img src={Four04image} alt="404" className="w-70 w-50-l"/>
                <Link to="/" className=""><h1 className="tc f3 measure lh-title mv5">Click to return to homepage</h1></Link>
            </div>
        </section>  
    <Footer/>
    </div>
)

export default Four04

