import {
  INIT_USER_INFO,
  CLEAR_USER_INFO,
  UPDATE_USER_INFO,
} from './types';

export const initUserInfo = (user) => ({
  type: INIT_USER_INFO,
  payload: user,
});

export const clearUserInfo = () => ({
  type: CLEAR_USER_INFO,
});

export const updateUserInfo = (key, value) => ({
  type: UPDATE_USER_INFO,
  payload: { key, value },
});