import { v4 } from 'uuid';

import Days from '../user/components/helpers/Days';
import JobTypes from '../user/components/helpers/JobTypes';
import Positions from '../user/components/helpers/Position';

class Saloon {
  constructor(name = '', location = '') {
    this._id = v4();
    this.name = name;
    this.location = location;
    this.workDays = [ ...Days ];
    this.jobTypes = [ ...JobTypes ];
    this.positions = [ ...Positions ];
  }
}

export class CurrentSaloon extends Saloon {
  constructor(name = '', location = '') {
    super(name, location);
    
    this.startDate = new Date();
  }
}

export class PreviousSaloon extends Saloon {
  constructor(name = '', location = '') {
    super(name, location);

    this.timeline = [ new Date(), new Date() ];
  }
}