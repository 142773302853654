import {
  INIT_USER_INFO,
  CLEAR_USER_INFO,
  UPDATE_USER_INFO,
} from './types';

const initState = {
  id: '',
  name: '',
  lastName: '',
  aliasName: '',
  email : '',
  about: '',
  contactInsta: '',
  contactPhone : '',
  photo: undefined,
  // currentSalonName : '',
  // currentSalonDateStart : '', 
  // redirectToProfile: false,
  // prevSalon: [],
  // error: '',
  // authorized: false,
}

export default (state = { ...initState }, { type, payload }) => {
  switch (type) {
    case INIT_USER_INFO:
      return { ...state, ...payload };
    case CLEAR_USER_INFO:
      return { ...initState };
    case UPDATE_USER_INFO:
      return { ...state, [payload.key]: payload.value };
    default:
      return state;
  }
}