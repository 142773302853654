import React from 'react';

const CheckBox = ({ id, value, isChecked, onChange }) => (
  <label className="f6 db mb2 mr3 checkbox-button">
    <input
      key={id}
      name={id}
      value={value}
      type="checkbox"
      onChange={onChange}
      checked={isChecked}
      className="checkbox-input"
    />
    <span className="checkbox-control" />
    <span className="checkbox-label">{value}</span>
  </label>
);

export default React.memo(CheckBox);