import React from 'react'
import Footer from './Footer'
import Directory from '../images/whair-directory.png'
import {Link} from 'react-router-dom'

const About = () => (
    <div> 
        <section className="mvh-50 flex flex-wrap justify-center items-center ph4 pv5 pa5-l"> 
            <div className="flex flex-column items-center">
                <div className="measure-narrow"><img src={Directory} alt="directory"/></div>
                <h1 className="tc measure lh-title">We want to reunite clients with their hair professionals and create a network that puts hairdressers and barbers first.</h1>
            </div>
        </section>  
        <section className=" mvh-50 flex flex-wrap justify-around items-center ph4 pv5 pa5-l"> 
            <div className="measure order-1 order-0-l tl tc-l mb4" style={{}}>
                <h1 className="lh-title">Directory</h1>
                <p className="lh-copy mb3">In an extremely competitive industry, visibility and client retention is important to us as hair professionals.</p>
                <p className="lh-copy ma0">We want hairdressers to feel confident in their careers and clients to be able to find them.</p>
            </div>
            <div className="measure order-1 order-0-l tl tc-l mb4" style={{}}>
                <h1 className="lh-title">Network</h1>
                <p className="lh-copy mb3">The ultimate goal is to create a platform for the hair industry where peers can share and inspire, help and encourage.</p>
                <p className="lh-copy ma0">We want hairdressers to feel confident in their careers and clients to be able to find them.</p>
            </div>
        </section>
        <section className="mvh-50 flex flex-wrap justify-center items-center ph4 pv5 pa5-l"> 
            <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                <p className="measure">How did Whair start?</p>
                <p className="measure-wide">Where is my hairdresser? A simple question that formed a simple idea. Whair was conceptualised in 2019 and the team have been working hard to bring this to life ever since.</p>
            </div>
            <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                <p className="measure">Who’s behind Whair?</p>
                <p className="measure-wide">A small group of hair professionals, designers, and developers. Like any small venture, we're starting small and aiming big.</p>
            </div>
            {/* <div className="flex flex-wrap justify-between bt b--light-gray w-100 mh5-l mh1 pv3">
                <p className="measure">What is the future of Whair?</p>
                <div className="mr2 pr3 measure-wide">
                <p className="">We're currently working on features which will be slowly be released.</p>
                <Link className="link silver hover-near-black" to="future">Find out more</Link>
                </div>
            </div> */}
        </section> 
        <Footer/>
    </div>
)

export default About

