import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import CheckBox from './CheckBox';
import {
  removeCurrentSaloon,
  updateCurrentSaloon,
  updateCurrentSaloonCheckFields,
} from '../../redux/current-saloon/actions';
import { DatePicker } from 'antd';

const styles = {
  wrapper: { 
    // marginTop: '80px'
},
  flex: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "start",
    width: "90%"
  },
  flexSm: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "start",
    width: "60%"
  },
};

const CurrentSaloon = ({
  _id,
  name,
  location,
  workDays,
  jobTypes,
  positions,
  startDate,
}) => {
  const dispatch = useDispatch();

  const onCheckGetData = (event) => ({
    checked: event.target.checked,
    fieldId: Number.parseInt(event.target.name),
  });

  const handleJobCheck = (event) => {
    const { fieldId, checked } = onCheckGetData(event);
    dispatch(updateCurrentSaloonCheckFields(_id, fieldId, checked, 'jobTypes'));
  };

  const handlePositionCheck = (event) => {
    const { fieldId, checked } = onCheckGetData(event);
    dispatch(updateCurrentSaloonCheckFields(_id, fieldId, checked, 'positions'));
  };
  
  const handleDaysChange = (event) => {
    const { fieldId, checked } = onCheckGetData(event);
    dispatch(updateCurrentSaloonCheckFields(_id, fieldId, checked, 'workDays'));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch(updateCurrentSaloon(_id, name, value));
  };

  const handleDateChange = (date) => {
    if (date) {
      dispatch(updateCurrentSaloon(_id, 'startDate', date.toDate()));
    }
  };

  const handleRemove = () => {
    dispatch(removeCurrentSaloon(_id));
  };

  return (
    <div style={styles.wrapper}>
      <div className="py-4 border" />
      <div>
        
      </div>
      <div className="w-100 mt2 mb3">
        <label className="f6 db mb2 mid-gray">Current salon name</label>
        <input
          type="text"
          name="name"
          value={name}
          onChange={handleChange}
          className="input-reset ba b--light-gray pa2 mb2 db w-100"
        />
      </div>
      <div className="w-100">
        <label className="f6 db mb2 mid-gray">Skills</label>
          <div style={styles.flex}>
            {
              jobTypes.map(({ id, value, isChecked }) => (
                <CheckBox
                  key={id}
                  id={id}
                  value={value}
                  isChecked={isChecked}
                  onChange={handleJobCheck}
                />
              ))
            }
          </div>
      </div>
      <div className="w-100 mt2 mb3">
        <label className="f6 db mb2 mid-gray">Additional roles</label>
          <div style={styles.flex}>
            {
              positions.map(({ id, value, isChecked }) => (
                <CheckBox
                  key={id}
                  id={id}
                  value={value}
                  isChecked={isChecked}
                  onChange={handlePositionCheck}
                />
              ))
            }
          </div>
      </div>
      
      
      <div className="w-100 mt2 mb3">
        <label className="f6 db mb2 mid-gray">Current salon location</label>
        <input
          type="text"
          name="location"
          value={location}
          onChange={handleChange}
          className="input-reset ba b--light-gray pa2 mb2 db w-100"
        />
      </div>
      
      <div className="w-100 mt2 mb3">
        <label className="f6 db mb2 mid-gray">Current salon dates</label>
        <DatePicker
          value={moment(startDate)}
          onChange={handleDateChange}
          className="w-100"
        />
      </div>

      <div className="w-100 mt2 mb3">
        <label className="f6 db mb2 mid-gray">Days working</label>
          <div style={styles.flex}>
            {
              workDays.map(({ id, value, isChecked }) => (
                <CheckBox
                  key={id}
                  id={id}
                  value={value}
                  isChecked={isChecked}
                  onChange={handleDaysChange}
                />
              ))
            }
          </div>
      </div>
      <button
          type="button"
          onClick={handleRemove}
          className="link ba b--moon-gray dark-gray ph3 pv2 mt2 mb4 dib"
        >
          Remove current salon
        </button>
    </div>
  );
};

export default React.memo(CurrentSaloon);