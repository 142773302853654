import React, {Component, Fragment} from 'react'
import {Route, Switch} from 'react-router-dom'
import Home from './core/Home'
import About from './core/About'
import Signup from './user/Signup'
import Signin from './user/Signin'
import Profile from './user/Profile'
import Menu from './core/Menu'
import Terms from './core/Terms'
import Cookie from './core/Cookie'
import Faq from './core/Faq'
import Privacy from './core/Privacy'
import Contact from './core/Contact'
import Community from './core/Community'
import Features from './core/Features'
import TheFuture from './core/TheFuture'
import Soon from './core/Soon'
import Four04 from './core/Four04'
import Users from './user/Users'
import EditUserProfile from './user/EditUserProfile'
import PrivateRoute from './auth/PrivateRoute'
import ForgotPassword from "./user/ForgotPassword"
import ResetPassword from "./user/ResetPassword"
import ConfirmEmail from './user/ConfirmEmail'

import Footer from './core/Footer'

import MoveStuffAround from './core/MoveStuffAround'

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default class MainRouter extends Component {

    render() {
        return (
    <div className="w-100" style={{paddingTop: 0}}>

    <Menu />
    {/* <MoveStuffAround/> */}
    <ScrollToTop />
        <Switch className="mb6" onUpdate={() => window.scrollTo(0, 0)}>
        
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Community} />
            <Route exact path="/profile" component={EditUserProfile} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password/:resetPasswordToken" component={ResetPassword}/>
            <Route exact path="/confirm/:activationToken" component={ConfirmEmail}/>
            <Route exact path="/directory" component={Users} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/signin" component={Signin} />
            <PrivateRoute exact path="/user/edit/:userId" component={EditUserProfile} />
            <Route exact path="/user/:userId" component={Profile} />
            <PrivateRoute exact path="/community" component={Community} />
            <Route exact path="/features" component={Features} />
            <Route exact path="/accounts" component={Features} />
            <Route exact path="/future" component={TheFuture} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/about" component={About} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/cookie" component={Cookie} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/soon" component={Soon} />
            <Route exact path="/search" component={Soon} />
            <Route exact path="/brands" component={Soon} />
            <Route component={Four04} />
        </Switch>
    </div>
)

        }}
