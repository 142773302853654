import React from 'react'
import {Link} from 'react-router-dom'

const Footer = () => (
    <div className=" MainFooter w-100" style={{backgroundColor: "#2b2b2b"}}>
            <div className="z-1 flex space-around w-100 w-50-l white mt4 ph2 ph5-l mb4">
                <div className="pa3 w-33">
                    <Link to="/about" className="white"><p className="f7 f6-l ma0">About Whair</p></Link>
                    <Link to="/faq" className="white"><p className="f7 f6-l ma0">FAQs</p></Link>
                    <Link to="/accounts" className="white"><p className="f7 f6-l ma0">Accounts</p></Link>
                    </div>
                <div className="pa3 w-33">
                    <Link to="/terms" className="white"><p className="f7 f6-l ma0">T&Cs</p></Link>
                    <Link to="/privacy" className="white"><p className="f7 f6-l ma0">Privacy Policy</p></Link>
                    <Link to="/cookie" className="white"><p className="f7 f6-l ma0">Cookie Policy</p></Link>
                </div>
                <div className="pa3 w-33">
                    <a href="https://instagram.com/whair.co.uk" target="black" className="white"><p className="f7 f6-l ma0">Instagram</p></a>
                    <Link to="/contact" className="white"><p className="f7 f6-l ma0">Contact</p></Link>
                </div>
            </div>
            <div className="pv4-l  pv4 ph4 ph5-l">
                <p className="f7 white lh-copy">Copyright 2020 Whair Directory Ltd. ©</p>
                <p className="f7 white lh-copy">Registered at 71–75 Shelton Street, Covent Garden, London, WC2H 9JQ</p>
            </div>
    </div>
)
export default Footer 