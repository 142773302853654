import {
  ADD_PREVIOUS_SALOON,
  INIT_PREVIOUS_SALOON,
  REMOVE_PREVIOUS_SALOON,
  UPDATE_PREVIOUS_SALOON,
  ADD_NEW_PREVIOUS_SALOON,
  UPDATE_CHECK_FIELDS_PREVIOUS_SALOON,
} from './types';

export const initPreviousSaloon = (saloons) => ({
  type: INIT_PREVIOUS_SALOON,
  payload: saloons,
});

export const addPreviousSaloon = (saloon) => ({
  type: ADD_PREVIOUS_SALOON,
  payload: saloon,
});

export const addNewPreviousSaloon = () => ({
  type: ADD_NEW_PREVIOUS_SALOON,
});

export const removePreviousSaloon = (id) => ({
  type: REMOVE_PREVIOUS_SALOON,
  payload: id,
});

export const updatePreviousSaloon = (id, key, value) => ({
  type: UPDATE_PREVIOUS_SALOON,
  payload: { id, key, value },
});

export const updatePreviousSaloonCheckFields = (saloonId, fieldId, isChecked, fieldName) => ({
  type: UPDATE_CHECK_FIELDS_PREVIOUS_SALOON,
  payload: { saloonId, fieldId, isChecked, fieldName },
});