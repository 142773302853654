import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import { isAuthenticated, signout } from '../auth'

import MoveStuffAround from './MoveStuffAround'

const isActive = (history, path) => {
    if (history.location.pathname === path) 
        return { color: 'black'}
            else return { color: '#333'};
}


const Menu = ({ history }) => (
    <div>
        {/* {!isAuthenticated() && (
            <MoveStuffAround/>
        )} */}

        <div className="flex fixed top-0 items-center justify-between bg-white w-100 border-box z-5 pv2 pv3-l ph3 ph4-l">
        
            {!isAuthenticated() && (
            <Link exact="true" to="/" className="value link dim dark-gray f4 ml pb1" style={{color: "black"}}>Whair<sup style={{fontSize:6, verticalAlign: 'top', lineHeight: '4.75em', marginLeft: '.3em'}} className="inter ttu tracked-mega fw3">Beta</sup></Link>
            )}
            {isAuthenticated() && (
            <Link exact="true" to="/community" className="value link dim dark-gray f4 ml pb1" style={{color: "black"}}>Whair</Link>
            )}

            <nav className="flex">
                {/* <Link exact="true" to="/directory" className="ph3 link dim mid-gray f6 fw3" style={isActive(history, "/directory")}>Directory</Link> */}
                {!isAuthenticated() && (
                    <React.Fragment>
                        <Link to="/signin" className="link dim dark-gray f6 fw3 ph3 " style={isActive(history, "/signin")}>Sign in</Link>
                        <Link to="/signup" className="link dim dark-gray f6 fw3 " style={isActive(history, "/signup")}>Sign up</Link>
                    </React.Fragment>
                )}
                {isAuthenticated() && ( 
                    <React.Fragment>
                        <Link 
                            to={`/user/${isAuthenticated().user._id}`} style={isActive(history, `/user/${isAuthenticated().user._id}`)}
                            className="link dim dark-gray f6 fw3 mr3">
                                My profile
                        </Link>
                        <span className="link dim dark-gray f6 fw3" onClick={() => signout(() => history.push('/'))} >Sign out</span>
                    </React.Fragment>
                )}
            </nav>
        </div>
    </div>
)


export default withRouter(Menu)