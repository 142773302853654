import React, { Component } from 'react'
import { signup } from '../auth'
import {Link} from 'react-router-dom'
import SocialLogin from './SocialLogin'

export default class Signup extends Component {
    constructor() {
        super()
        this.state = {
            name: "",
            lastName: "",
            email: "",
            password: "",
            error: "",
            open: false        }
    }
    handleChange = (input) => (event) => {
        this.setState({error: ""})
        this.setState({ [input]: event.target.value})
    }
    clickSubmit = event => {
        event.preventDefault()
        const {name, lastName, email, password} = this.state
        const user = {
            name,
            lastName,
            email,
            password
        }
        console.log(user)
        signup(user)
            .then(data => {
                if(data.error) this.setState({error: data.error })
                    else this.setState({
                        name: "",
                        lastName: "",
                        email: "",
                        password: "",
                        error: "",
                        open: true
                    })
                    
            })
    }
    
    
    render() {
        const {name, lastName, email, password, error, open} = this.state
        // Redirect to Signin on successful sign up
        // if(open){
        //     return <Redirect to={`/signin`}/>
        // }
        return (
            <div className="container flex w-100 flex-wrap justify-start">
                <div className="form ma3 pa4 pa4-l ma5-l ba b--light-gray h-40" style={{width: "360px"}}>
                    <h1 className="fw3 mt2 mb4 dark-gray">Sign up</h1>
                    <form>
                        <div className="w-100 mt2 mb3">
                            <label className="f6 fw3 b db mb2 mid-gray">Name</label>
                            <input 
                                onChange={this.handleChange("name")} 
                                type="text" 
                                value={name}
                                className="input-reset ba b--light-gray pa2 mb2 db w-100"
                            />
                        </div>
                        <div className="w-100 mt2 mb3">
                            <label className="f6 fw3 b db mb2 mid-gray">Last name</label>
                            <input 
                                onChange={this.handleChange("lastName")} 
                                type="text" 
                                value={lastName}
                                className="input-reset ba b--light-gray pa2 mb2 db w-100"
                            />
                        </div>
                        <div className="w-100 mt2 mb3">
                            <label className="f6 fw3 b db mb2 mid-gray">Email</label>
                            <input 
                                onChange={this.handleChange("email")} 
                                type="email"
                                value={email}
                                className="input-reset ba b--light-gray pa2 mb2 db w-100" 
                            />
                        </div>
                        <div className="w-100 mt2 mb3">
                            <label className="f6 fw3 b db mb2 mid-gray">Password</label>
                            <input 
                                onChange={this.handleChange("password")} 
                                type="password" 
                                value={password}
                                className="input-reset ba b--light-gray pa2 mb2 db w-100" 
                            />
                        </div>
                        <span onClick={this.clickSubmit} className="mv3 link white bg-black dim ph3 pv2 ttu ba b--dark-gray f6 mr3">Submit</span>
                        <SocialLogin />
                    </form>
                    <div className="f6 alert dark-red" style={{display: error ? '' : 'none'}}>{error}</div>
                </div>
                                       
                    
                <div className="measure mh5 mt5">
                    
                    
                </div>
                <div
                    className="alert alert-info"
                    style={{ 
                        position: 'absolute', 
                        top: 24, 
                        left: `50%`, 
                        transform: 'translate(-50%, -10%)',
                        background: '#444', 
                        borderRadius: '4px',
                        margin: 24,
                        padding: 24,
                        color: 'white', 
                        display: open ? "" : "none" 
                        }}
                >
                    New account is successfully created. Please 
                    <Link to="/signin" className="white link">sign in</Link>.
                </div>
            </div>
        )
    }
}
