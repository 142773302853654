import React from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { isAuthenticated } from '../auth';
import { read, update, updateUser } from './apiUser';
import defaultProfileImage from '../images/default-user-image.png';

import CurrentSaloon from './components/CurrentSaloon';
import PreviousSaloon from './components/PreviousSaloon';

import {
  initUserInfo,
  updateUserInfo,
} from '../redux/user-info/actions';
import {
  initCurrentSaloon,
  addNewCurrentSaloon,
} from '../redux/current-saloon/actions';
import {
  initPreviousSaloon,
  addNewPreviousSaloon,
} from '../redux/previous-saloon/actions';

const styles = {
  flex: {
    width:"100%",
    display:"flex",
    flexWrap:"wrap",
    marginTop:"50px",
    justifyContent:"space-between",
  },
};

const EditUserProfile = ({ userInfo, currentSaloons, previousSaloons, dispatch }) => {
  const params = useParams();

  const [img, setImg] = React.useState(defaultProfileImage);
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState('');
  const [authorized, setAuthorized] = React.useState(false);

  const [fileSize, setFileSize] = React.useState(0);

  React.useEffect(() => {
    if (!isAuthenticated() || isAuthenticated().user._id !== params.userId) {
    // if (!isAuthenticated()) {
      return setLoading(false);
    }

    read(params.userId, isAuthenticated().token)
    // read(isAuthenticated().user._id, isAuthenticated().token)
      .then((data) => {
        if (data.error) {
          return setRedirect('/');
        }

        setLoading(false);
        setAuthorized(true);

        const user = {
          id: data._id,
          name: data.name,
          lastName: data.lastName,
          aliasName: data.aliasName,
          email: data.email,
          contactInsta: data.contactInsta,
          contactPhone: data.contactPhone,
        };

        dispatch(initUserInfo(user));
        dispatch(initCurrentSaloon(data.currSalon));
        dispatch(initPreviousSaloon(data.prevSalon));

        setImg(`${process.env.REACT_APP_API_URL}/user/photo/${data._id}?${new Date().getTime()}`);
      })
      .catch((error) => {
        console.log('Error:', error);
        setRedirect('/');
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch(updateUserInfo(name, value));
  };

  const handleImageUpload = (event) => {
    const { name, files } = event.target;
    const [ file ] = files;

    setFileSize(file.size || 0);

    dispatch(updateUserInfo(name, file));
  };

  const validate = () => {
    setError('');

    if (fileSize > 250000) {
      return setError('File size should be less than 250 KBs.');
    }

    if (userInfo.name.trim().length <= 0) {
      return setError('Name field is required.');
    }

    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(userInfo.email)) {
      return setError('Valid email address is required.');
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();

    validate();

    if (error) return;

    for (let key in userInfo) {
      const value = userInfo[key];

      if (key === 'photo' && !userInfo[key]) {
        continue;
      }
      formData.append(key, value);
    }

    const currSal = currentSaloons.map((saloon) => {
      const sal =  { ...saloon };
      delete sal._id;
      return sal;
    }).filter(({ name, location }) => name && location);

    const prevSal = previousSaloons.map((saloon) => {
      const sal =  { ...saloon };
      delete sal._id;
      return sal;
    }).filter(({ name, location }) => name && location);

    formData.append('prevSalon', JSON.stringify(prevSal));
    formData.append('currSalon', JSON.stringify(currSal));

    update(params.userId, isAuthenticated().token, formData)
      .then((data) => {
        if (data.error) {
          console.log(data.error);
          alert(data.error)
        }
        setRedirect('/');
      })
      .catch((error) => {
        console.log(error)
        alert(error)
      });
  };

  const handleAddCurrentSaloon = () => dispatch(addNewCurrentSaloon());

  const handleAddPreviousSaloon = () => dispatch(addNewPreviousSaloon());

  if (redirect) {
    return <Redirect to={redirect} />;
  }
  if (loading) {
    return <h1 className="text-center silver">Please hold...</h1>
  }
  if (!authorized) {
    return <h1 className="text-center">Unauthorized...</h1>;
  }

  return (
    <div className="container w-100 bg-near-white ph3 pv5 pa5-l">
      <div className="form bg-white w-40-ns w-100-m ph3 pv5 pa5-l" >
        {error && <div className="f6 alert dark-red">{error}</div>}
                  <div className="flex space-between items-center h4">
                    <img
                      src={img}
                      alt={userInfo.name}
                      className="w4 h4 br-100 mb5"
                      onError={() => setImg(defaultProfileImage)}
                    />
                    <div className="w-100 mt2 mb3">
                      <input 
                          type="file"
                          name="photo"
                          accept="image/*" 
                          onChange={handleImageUpload} 
                          className="input-reset ba b--light-gray pa2 mb2 db w-100"
                      />
                    </div>
                  </div>
                  <div>
                    <h1 className="f3">Basic information</h1>
                    <div className="w-100 mt2 mb3">
                      <label className="f6 db mb2 dark-gray">First name</label>
                      <input 
                          onChange={handleChange} 
                          name="name"
                          value={userInfo.name}
                          type="text"
                          className="input-reset ba b--light-gray pa2 mb2 db w-100"
                      />
                    </div>
                    <div className="w-100 mt2 mb3">
                      <label className="f6 db mb2 dark-gray">Last name</label>
                      <input 
                          onChange={handleChange} 
                          name="lastName"
                          value={userInfo.lastName}
                          type="text"
                          className="input-reset ba b--light-gray pa2 mb2 db w-100"
                      />
                    </div>
                    <div className="w-100 mt2 mb3">
                      <label className="f6 db mb2 dark-gray">Known as</label>
                      <input 
                          onChange={handleChange} 
                          name="aliasName"
                          value={userInfo.aliasName}
                          type="text"
                          className="input-reset ba b--light-gray pa2 mb2 db w-100"
                      />
                    </div>

                    <div className="w-100 mt2 mb3">
                      <label className="f6 db mb2 dark-gray">Email</label>
                      <input 
                          type="email"
                          name="email"
                          value={userInfo.email}
                          onChange={handleChange} 
                          className="input-reset ba b--light-gray pa2 mb2 db w-100"
                      />
                    </div>

                    <div className="w-100 mt2 mb3">
                      <label className="f6 db mb2 dark-gray">Phone</label>
                      <input 
                          type="text"
                          name="contactPhone"
                          value={userInfo.contactPhone}
                          onChange={handleChange} 
                          className="input-reset ba b--light-gray pa2 mb2 db w-100"
                      />
                    </div>

                    <div className="w-100 mt2 mb3">
                      <label className="f6 db mb2 dark-gray">Instagram</label>
                      <input 
                          type="text"
                          name="contactInsta"
                          value={userInfo.contactInsta}
                          onChange={handleChange} 
                          className="input-reset ba b--light-gray pa2 mb2 db w-100"
                      />
                    </div>
                  </div>
                </div>
      <div className="form bg-white w-40-ns w-100-m mv5 ph3 ph5-l pv4-l" >
        <div className="">
          <h1 className="f3">Current job(s)</h1>
            {currentSaloons.map((saloon) => (
              <CurrentSaloon
                key={saloon._id}
                {...saloon}
              />
            ))}
            <button onClick={handleAddCurrentSaloon} className="link ba b--moon-gray dark-gray ph3 pv2 mt2 mb4 dib">Add another salon +</button>
        </div>
      </div>

      <div className="form bg-white w-40-ns w-100-m mv5 ph3 pv3 ph5-l pv4-l" >
        <div className="">
            <h1 className="f3 pb3">Previous job(s)</h1>
            {previousSaloons.map((saloon) => (
              <PreviousSaloon
                key={saloon._id}
                {...saloon}
              />
            ))}
            <button onClick={handleAddPreviousSaloon} className="link ba b--moon-gray dark-gray ph3 pv2 mt2 mb4 dib">Add another salon +</button>
        </div>
      </div>
          <div style={styles.flex}> 
            <button onClick={handleSubmit} className="link ba white bg-black ph3 pv2 mt2 mb4 dib">Update</button>
          </div>
      </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
  currentSaloons: state.currentSaloon,
  previousSaloons: state.previousSaloon,
});

export default connect(mapStateToProps, null)(EditUserProfile);