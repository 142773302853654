import React from 'react'
import Footer from './Footer'
import Directory from '../images/whair-directory.png'
import Search from '../images/search-directory.png'
import Network from '../images/network-industry.png'

const TheFuture = () => (
    <div> 
        <section className="mvh-50 flex flex-wrap justify-center items-center ph4 pv5 pa5-l"> 
            <div className="flex flex-column items-center">
                <div className="measure-narrow"><img src={Directory} alt="directory" /></div>
                <h1 className="tc measure lh-title">We’re starting out small and aiming big.</h1>
            </div>
        </section>  
        <section className="w-100 mvh-50 flex flex-column flex-wrap justify-around items-center ph4 pv5 pa5-l"> 
            <div className="tl mb4 flex flex-wrap justify-center items-center bt b--light-gray" style={{}}>
                <div className="w-100 w-50-l tc mv5">
                    <img src={Search} alt="search" className="w-30"/>
                </div>
                <div className="w-100 w-50-l">
                    <h1 className="lh-title">Searching the directory</h1>
                    <p className="lh-copy mb3">The ability to search a vast directory of hairdressers and barbers, by location, will be one of Whair’s staple features.</p>
                    <p className="lh-copy mb3">Clients who have lost their hair professionals in the past will be reunited with ease.</p>
                    <p className="lh-copy ma0">Clients who who don’t want to lose their hair professional will never have to worry again.</p>
                </div>
            </div>
            <div className="tl mb4 flex flex-wrap justify-center items-center bt b--light-gray" style={{}}>
                <div className="w-100 w-50-l tc mv5">
                    <img src={Network} alt="network" className="w-30"/>
                </div>
                <div className="w-100 w-50-l">
                    <h1 className="lh-title">Network within industry</h1>
                    <p className="lh-copy mb3">The ability to search a vast directory of hairdressers and barbers, by location, will be one of Whair’s staple features.</p>
                    <p className="lh-copy mb3">Clients who have lost their hair professionals in the past will be reunited with ease.</p>
                    <p className="lh-copy ma0">Clients who who don’t want to lose their hair professional will never have to worry again.</p>
                </div>
            </div>
            
        </section>
        
    </div>
)

export default TheFuture

