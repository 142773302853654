export const daysOfTheWeek = [
    { id:1, value: 'Mon', isChecked:false},
    { id:2, value: 'Tue', isChecked:false},
    { id:3, value: 'Wed', isChecked:false},
    { id:4, value: 'Thu', isChecked:false},
    { id:5, value: 'Fri', isChecked:false},
    { id:6, value: 'Sat', isChecked:false},
    { id:7, value: 'Sun', isChecked:false},
  ];

export default daysOfTheWeek;
  