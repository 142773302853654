import React, {Component} from 'react'
import { Parallax } from "react-parallax"

import {isAuthenticated} from '../auth'
import {read} from '../user/apiUser'


import WhairGradient from '../images/whair-gradient.png'

import defaultProfileImage from '../images/default-user-image.png'
import Video from '../images/future.mp4'

import {Link} from 'react-router-dom'

import Footer from '../core/Footer'


class  Community extends React.Component {
    constructor(){
        super()
        this.state = {
            user:"",
        }
    }

    init = (userId) => {
        const token = isAuthenticated().token
        read(userId, token)
        .then(data => {
            if(data){
                if(data.error){
                    this.setState({
                        redirectToSignIn: true
                    })
                } else {
                    this.setState({
                        user: data
                    })
                }
            }
        })
    }

    componentDidMount(){
        const userId = this.props.match.params.userId
        this.init(userId)
    }
    componentWillReceiveProps(props){
        const userId = props.match.params.userId
        this.init(userId)
    }
    
    render(){
        const {user} = this.state
        return(
        <div className="black">
            <section className="vh-50 mh3  mh4-l mb4-l" style={{backgroundImage: `url(${WhairGradient})`}}>
                    <div className="measure measure-wide-l white tl mb1 mh1" style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)'}}>
                    </div>
            </section>
            <div className="bg-white">
        
            <section className="ma2 ma3-l pa2 pa4-l relative">
               <div className="w-100 bt b--black justify-between flex ma0">
                   <p className="lh-copy f4 measure">Welcome</p>
               </div>
               <div className="pb3">
               <p className="lh-copy ma0">Thank you for being part of Whair</p>
               <p className="lh-copy ma0">Please take some time to fill out your profile.</p>
                    {isAuthenticated().user && isAuthenticated().user._id === user._id && (
                        <React.Fragment>
                            <Link 
                                to={`/user/edit/${user._id}`}
                                className="mb3 link white bg-black dim ph3 pv2 ttu ba b--white f7 f6-l">Edit Profile
                            </Link>
                        </React.Fragment>
                    )}
               {/* <div className="mt4"><Link to="/user/edit/:userId" className="mb3 link white bg-black dim ph3 pv2 ttu ba b--white f7 f6-l">Edit Profile</Link></div> */}
               </div>
            </section>
            <section className=" ma2 ma3-l pa2 pa4-l relative">
               <div className="w-100 bt b--black justify-between flex ma0">
                   <p className="lh-copy f4 measure">The Future</p>
               </div>
               <div className="pb3">
               <p className="lh-copy ma0">The ability to search a vast directory of hairdressers and barbers, by location, will be one of Whair’s staple features.</p>
               <p className="lh-copy ma0">This is a preview of how your clients may find you through our search page.</p>
               <p className="lh-copy ma0">Alternatively, clients invited by you will automatically be added to your client list.</p>
               </div>
               <div className="w-100 pa4 flex justify-center items-center" style={{backgroundColor: '#DDDBDB'}}>
                <video style={{  maxWidth: '20vmax'}} autostart controls loop autoPlay src={Video} type="video/mp4" />
               </div>
            </section>
            <section className=" ma2 ma3-l pa2 pa4-l relative">
               <div className="w-100 bt b--black justify-between flex ma0">
                   <p className="lh-copy f4 measure">Coming soon – hold please !</p>
               </div>

               <div style={{opacity: '.4'}}>
                                        <div className="w-100 mb3 flex">
                                            <img src={defaultProfileImage} alt='{user.name}' className="w4 h4 br-100"/>
                                            <div className="ml3 w-100-m">
                                                <h1 className=" f3-m black-80 mv0 ttc ">Name</h1>
                                                <h2 className="fw3 f6 light-silver mv0">Salon, Location</h2>
                                            </div>
                                        </div>
                                        <div className="w-100 h4 flex flex-column justify-center items-center" >
                                            <h2 className="w-100 fw3 f6 bt b--light-gray pv1 ma0 gray"><span className="black-80">Job role: </span>—</h2>
                                            <h2 className="w-100 fw3 f6 bt b--light-gray pv1 ma0 gray"><span className="black-80">Availabilty: </span>—</h2>
                                            <h2 className="w-100 fw3 f6 bt b--light-gray pv1 ma0 gray"><span className="black-80">Date joined: </span>—</h2>
                                        </div>
                                        <div className="w-100 bt b--black justify-between flex mb3">
                                            <p className="lh-copy f4 mt2 measure">My network (0)</p>
                                            <p className="lh-copy f4 measure">+</p>
                                        </div>
                                        <div className="w-100 bt b--black justify-between flex mb3">
                                            <p className="lh-copy f4 mt2 measure">My clients (0)</p>
                                            <p className="lh-copy f4 measure">+</p>
                                        </div>
                                        <div className="w-100 bt b--black justify-between items-center flex mb3">
                                            <p className="lh-copy f4 mt2 measure">Ask the industry</p>
                                            <p className="lh-copy f5 measure">View Latest →</p>
                                        </div>
                                        <div className="w-100 bt b--gray justify-between items-center flex mb3">
                                            <div className="mt2">
                                                <p className="lh-copy f5 measure">Am I charging the right amount for services?</p>
                                                <p className="lh-copy f7 measure">Asked by Sylvia Patricia a moment ago</p>
                                            </div>
                                            <p className="lh-copy f5 measure">→</p>
                                        </div>
                                        <div className="w-100 bt b--gray justify-between items-center flex mb3">
                                            <div className="mt2">
                                                <p className="lh-copy f5 measure">What’s your favourite toner for pastel pinks?</p>
                                                <p className="lh-copy f7 measure">Asked by Sophia Bradley 1 hour ago</p>
                                            </div>
                                            <p className="lh-copy f5 measure">→</p>
                                        </div>
                                        <div className="w-100 bt b--gray justify-between items-center flex mb3">
                                            <div className="mt2">
                                                <p className="lh-copy f5 measure">Can anybody recommend some online courses? </p>
                                                <p className="lh-copy f7 measure">Asked by Norah Williams 1 hour ago</p>
                                            </div>
                                            <p className="lh-copy f5 measure">→</p>
                                        </div>
               </div>
            </section>
            <section className="ma2 db ma3-l pa2 pa4-l">
               <div className="w-100 mb3">
                   <p className="lh-copy f4 measure mv2">How can I help?</p>
               </div>
               <div className="w-100 flex flex-wrap justify-around items-start mv4" >
                    <div className="measure order-1 order-0-l tc mb4" style={{}}>
                        <h1 className="lh-title">We won’t start without you!</h1>
                        <p className="lh-copy mb3">During this stage, we are exclusively signing up hairdressers/barbers and registering interest from other industry professionals (non-hairdressers).</p>
                        <p className="lh-copy ma0">This will continue to be our main focus until we have reached our first target of 1000</p>
                    </div>
                    <div className="measure order-1 order-0-l tc mb4" style={{}}>
                        <h1 className="lh-title">Spread the word</h1>
                        <p className="lh-copy mb3">While we’re developing new features for you, we would appreciate you sharing this with as many hairdressers and barbers as you can.</p>
                    </div>
               </div>
            </section>
            </div>
            <Footer/>

    </div>
)}}
export default Community