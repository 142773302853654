import { CurrentSaloon } from '../../classes/Saloon';

import {
  ADD_CURRENT_SALOON,
  INIT_CURRENT_SALOON,
  REMOVE_CURRENT_SALOON,
  UPDATE_CURRENT_SALOON,
  ADD_NEW_CURRENT_SALOON,
  UPDATE_CHECK_FIELDS_CURRENT_SALOON,
} from './types';

const initState = [ new CurrentSaloon() ];

export default (state = [ ...initState ], { type, payload }) => {
  switch (type) {
    case INIT_CURRENT_SALOON:
      return payload.length <= 0
        ? [ ...initState ]
        : [ ...payload, ...initState ];
    case ADD_CURRENT_SALOON:
      return [ ...state, payload, new CurrentSaloon() ];
    case ADD_NEW_CURRENT_SALOON:
      return [ ...state, new CurrentSaloon() ];
    case REMOVE_CURRENT_SALOON:
      return state.filter((saloon) => saloon._id !== payload);
    case UPDATE_CURRENT_SALOON:
      return state.map((saloon) => (
        saloon._id === payload.id
          ? { ...saloon, [payload.key]: payload.value }
          : { ...saloon }
      ));
    case UPDATE_CHECK_FIELDS_CURRENT_SALOON:
      console.log(payload);
      return state.map((saloon) => (
        saloon._id === payload.saloonId
          ? {
            ...saloon,
            [payload.fieldName]: saloon[payload.fieldName].map((field) => (
              field.id === payload.fieldId
                ? { ...field, isChecked: payload.isChecked }
                : { ...field }
            ))
          }
          : { ...saloon }
      ));
    default:
      return state;
  }
};