import React from 'react'
import {Link} from 'react-router-dom'
import Footer from './Footer'

const Privacy = () => (
    <div>
        <div className='w-100 pa4 flex justify-center'>
            <div className="measure-wide-m w-50-l pa4 mb6">
                <h1 className="fw3 dark-gray tc">Privacy</h1>

                <h2 className="fw3 lh-title dark-gray pv3">
                    Accuracy
                </h2>
                <p className="lh-copy silver mb1">
                    Every effort is taken to ensure that the information contained in this website is both accurate and complete.
                </p>
                <h2 className="fw3 lh-title dark-gray pv3">
                    Availabilty
                </h2>
                <p className="lh-copy silver">
                    We cannot guarantee uninterrupted access to this website, or the sites to which it links. We accept no responsibility for any loss, disruption or damage arising from the lack of access to information or broken links.
                </p>
                <h2 className="fw3 lh-title dark-gray pv3">
                    Cookies
                </h2>
                <p className="lh-copy silver mb1">
                    Please read our <Link to="/cookies">Cookies Policy</Link>
                </p>
                <h2 className="fw3 lh-title dark-gray pv3">
                    Copyright
                </h2>
                <p className="lh-copy silver mb1">
                    The material on this site is subject to copyright protection of Whair Ltd. unless it is information found on a users profile, or unless otherwise indicated. The copyright protected material (other than the Royal Arms and departmental or agency logos) may be reproduced free of charge in any format or medium for research, private study or for internal circulation within an organisation. This is subject to the material being reproduced accurately and not used in a misleading context. Where any of Whair Ltd’s copyright items on this site are being republished or copied to others (either wholly or partly), the source of the material must be identified and the copyright status acknowledged.
                </p>
                <p className="lh-copy silver mb1">
                    Rather than copy or use information on this website we would prefer users to create links to this site.
                </p>
                <p className="lh-copy silver mb1">
                    The permission to reproduce the protected material does not extend to any material on this site that is identified as being the copyright of a third party. Authorisation to reproduce such material must be obtained from the copyright holders.
                </p>
                <h2 className="fw3 lh-title dark-gray pv3">
                    Intellectual property 
                </h2>
                <p className="lh-copy silver mb1">
                    The names, images and logos identifying Whair Ltd are the proprietary marks of Whair Ltd Copying our materials or any third party logos accessed via this website is not permitted without the prior approval of the relevant copyright owner.
                </p>
                <h2 className="fw3 lh-title dark-gray pv3">
                    Links property 
                </h2>
                <p className="lh-copy silver mb1">
                    All hyperlinks from this website are provided for information and convenience only. Elected using our links policy. Links are provided for information and convenience only. We cannot accept responsibility for the sites linked to, or the information found there. A link does not imply an endorsement of a site; conversely, the absence of links to a particular website does not imply lack of endorsement.
                </p>
                <p className="lh-copy silver mb1">
                    You do not have to ask permission to link directly to pages hosted on this site. However, we do not permit our pages to be loaded into frames on your site. The pages must load into the user’s entire window. You must not use the logo to link to our site without prior permission.
                </p>
            </div>
        </div>
        <Footer/>
    </div>
)

export default Privacy

